import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"

import { AnimatePresence } from "framer-motion"

import Home from "./Home";
import FormStart from "./FormStart";
import Form from "./Form";
import FormEnd from "./FormEnd";

function AnimatedRoutes() {
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        navigate('/home')
    }, []);

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/home" element={<Home />} />
                <Route path="/start" element={<FormStart />} />
                <Route path="/form" element={<Form />} />
                <Route path="/end" element={<FormEnd />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes;