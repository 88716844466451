import 'react-toastify/dist/ReactToastify.css';

import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function FormStart() {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [modal, setModal] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleChange = (newValue) => {
    document.body.style.overflow = newValue ? 'hidden' : 'auto'
  };

  useEffect(() => {
    handleChange(modal);
  }, [modal]);

  return (
    <>
    <motion.div
      className="flex w-full min-h-screen relative"
      initial={{ x: window.innerWidth }}
      animate={{ x: 0, transition: { duration: 0.5 } }}
    >
        <div className="w-[24px] md:w-[33.33%] bg-no-repeat bg-top bg-cover" style={ { backgroundImage: 'url(/images/hello.jpg)' } }></div>
          <div className="flex flex-1 bg-[#090909]">
            <div className="flex flex-col justify-center items-center flex-1 bg-white rounded-l-3xl p-[34px]">
                <div className="flex flex-1 flex-col justify-center items-center max-w-[722px]">
                    <p className="text-primary text-[32px] leading-[48px] text-center">Ahora necesitamos que respondas un breve cuestionario y te enviaremos la visibilidad que tienes en LinkedIn por email.</p>

                    <div className="flex w-full relative justify-start items-start mt-[40px] gap-2 select-none">
                      <input type="checkbox" id="privacy_policy" className="relative peer appearance-none w-4 h-4 min-w-[1rem] min-h-[1rem] border-2 border-primary rounded-sm bg-white cursor-pointer checked:bg-primary mt-[9px]" checked={isChecked} onChange={handleCheckboxChange} />
                      <label htmlFor="privacy_policy" className="cursor-default font-normal text-primary text-base leading-8">He leído y acepto la <span onClick={() => setModal(true)} className="underline font-bold text-[#00819D]">política de proteción de datos</span>.</label>
                        <svg
                          className="
                            absolute 
                            w-3 h-3 top-[11.5px] min-w-[0.75rem] min-h-[0.75rem]
                            ml-0.5
                            hidden peer-checked:block
                            "
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#FFFFFF"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>

                    <div className="flex w-full justify-center mt-[56px] min-h-[80px] items-center">
                        <button type="button" className="text-primary hover:text-white bg-secondary disabled:text-primary text-2xl leading-6 hover:leading-8 disabled:leading-6 font-medium p-4 hover:p-6 disabled:p-4 rounded-[10px] transition-all disabled:opacity-20" disabled={!isChecked} onClick={() => navigate('/form')}>¡Empezamos!</button>
                    </div>

                    <img alt="" src="/icons/form-start-icon.svg" className="mt-[68px]" />
                </div>
                
                <div className="flex w-full justify-end">
                    <img alt="" src="/icons/logo.svg" />
                </div>
            </div>
        </div>

        <div tabindex="-1" ariaHidden="true" className="fixed z-50 w-full p-6 md:p-10 pb-0 overflow-x-hidden overflow-y-auto inset-0 min-h-full bg-primary/30 transition-all" style={ { display: modal ? 'block' : 'none' } }>
          <div className="flex justify-center relative w-full">
              <div className="relative max-w-[1200px] bg-white rounded-[20px]">
                  <div className="flex items-center justify-between p-6 pb-4">
                      <h3 className="w-full text-center text-base leading-8	font-semibold text-black">
                          Política de privacidad
                      </h3>
                      <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center" onClick={() => setModal(false)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M3 14L12.6 2M3 2L12.6 14L3 2Z" stroke="#2A1E4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className="p-6 pt-0 text-black text-base leading-8 font-light">
                      <p className="font-medium underline">Antes de facilitar sus datos, es necesario que lea y acepte la política de privacidad:</p>
                      <p className='mt-4'>En cumplimiento del artículo 13 Reglamento (UE) 2016/ 679, de 27 de abril (en lo sucesivo RGPD), así como el artículo 11 de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (en lo sucesivo PD – GDD), le informamos que el</p>
                      <p><span className="font-medium underline">Responsable del tratamiento</span>: BROS SELECCIÓN SL | CIF: B43949908 | Dirección: <a href="https://maps.app.goo.gl/aqR8xh5xwY9bJ75F7" rel="noreferrer" target='_blank' className='underline'>CL/ SERRANO, 38 - 4º 28001 MADRID</a> | Correo electrónico: <a rel="noreferrer" href="mailto:administracion@brosgroup.es" className='underline'>administracion@brosgroup.es</a> | Página web: <a rel="noreferrer" href="https://brosgroup.es/" target='_blank' className='underline'>www.brosgroup.es</a></p>
                      <p className='mt-4'><span className="font-medium underline">Finalidades</span>: gestionará los datos que nos facilite para realizar un estudio sobre la visibilidad de su perfil en plataformas de búsqueda de empleo, así como en su caso, para asesorarle en la búsqueda de empleo y enviarle información por correo electrónico que pueda ser de su interés, pudiendo oponerse en cualquier momento a la recepción de estas comunicaciones, en los términos del artículo 13.2.c RGPD</p>
                      <p className='mt-4'><span className="font-medium underline">Legitimación</span>: todas las finalidades descritas en el apartado anterior se basan en el consentimiento del propio interesado (art. 6.1.a RGPD), así como interés legítimo con de nuestra sociedad para enviarle información relacionada con el evento en el que ha participado (artículos 6.1.f y Considerando 47 RGPD y artículo 22.2. LSSICE).</p>
                      <p className='mt-4'><span className="font-medium underline">Conservación de los datos</span>: sus datos se conservarán el tiempo estrictamente necesario y conforme a los plazos que puede consultar en la política de privacidad en de nuestra web o solicitándolo al correo electrónico.</p>
                      <p className='mt-4'><span className="font-medium underline">Destinatarios</span>: no se cederán datos a terceros, salvo obligación legal.</p>
                      <p className='mt-4'><span className="font-medium underline">Derechos</span>: puede ejercer los derechos de acceso, rectificación, supresión, portabilidad, limitación del tratamiento y oposición escribiendo un correo electrónico o en nuestra oficina. Si considera que sus derechos han sido vulnerados, puede interponer una reclamación ante la Agencia Española de Protección de Datos, a través de su sede electrónica en <a rel="noreferrer" href="https://www.aepd.es/" target='_blank' className='underline'>www.aepd.es</a>.</p>
                      <p className='mt-4'><span className="font-medium underline">Información adicional</span>: toda la información sobre el tratamiento de sus datos puede ser ampliada y se encuentra detallada en la política de privacidad de nuestra página web o mediante solicitud al correo electrónico de contacto.</p>
                  </div>
              </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default FormStart;
